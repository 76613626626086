<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <EstablishmentRoleForm
      v-if="!isLoading"
      :establishmentRole="establishmentRole"
      :imageSrc="imageSrc"
      v-on:addOrUpdateEstablishmentRole="addOrUpdateEstablishmentRole()"
      :submitName="$t('add')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "./../../../utils/create-toast-mixin";
import PreLoader from "./../../../components/general/PreLoader.vue";
import { STATUS } from "./../../../utils/constants";
import EstablishmentRoleImg from "./../../../assets/images/establishment-role.svg";
import EstablishmentRoleForm from "./../../../components/settings/establishmentRoles/EstablishmentRoleForm.vue";
import EstablishmentRole from "./../../../models/settings/establishmentRoles/EstablishmentRole";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    EstablishmentRoleForm,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      imageSrc: EstablishmentRoleImg,
      establishmentRole: new EstablishmentRole(),
    };
  },
  methods: {
    async addOrUpdateEstablishmentRole() {
      this.isLoading = true;
      try {
        const response =
          await this.establishmentRole.addOrUpdateEstablishmentRole(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.establishmentRole.setInitialValue();
          this.showMsg(response.data.msg, true);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
};
</script>
